import axios from '@axios'

export default {
  namespaced: true,
  state: {
    showOverlay: true,
    calendarOptions: [
      {
        color: 'danger',
        label: 'Licencia de Navegacion',
        id: 1
      },
      // {
      //   color: 'primary',
      //   label: 'PER',
      //   id: 2
      // },
      // {
      //   color: 'warning',
      //   label: 'PNB',
      //   id: 3
      // },
      {
        color: 'success',
        label: 'Club',
        id: 4
      },
      {
        color: 'info',
        label: 'PER online',
        id: 5
      },
      {
        color: 'yellow',
        label: 'PER motor',
        id: 6
      },
      {
        color: 'cyan',
        label: 'PER motor y vela',
        id: 7
      },
      {
        color: 'teal',
        label: 'PER motor, vela y Baleares',
        id: 8
      },
      {
        color: 'pink',
        label: 'PNB motor',
        id: 10
      },
      {
        color: 'primary',
        label: 'PNB motor y Vela',
        id: 11
      },
    ],
    selectedCalendars: ['Licencia de Navegacion', 'Club', 'PER online', 'PER motor', 'PER motor y vela', 'PER motor, vela y Baleares', 'PNB motor', 'PNB motor y Vela'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, allCalendars}) {
      // console.log(allCalendars)
      // // console.log(calendars.map(o => o['id']))
      var getCourseIds = []
      calendars.forEach(element => {
        allCalendars.filter(course => {
          if(course.label == element) {
            getCourseIds.push(course.id)
            return course.id
          }
        })
      });

      //calendars.find((calendar) => calendar.name == );
      return new Promise((resolve, reject) => {
        axios
          .get('student-calendar-event', {
            params: {
              calendars: getCourseIds.join(','),
            },
          })
          .then(response => resolve(response.data.data.events))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .post('store-event',  event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/edit-event/${event.id}`, event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/delete-event/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
